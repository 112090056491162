// /var/www/website2023/afraapache.com/react-app/src/pages/PrivacyPolicy.js

import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import '../style/page.scss';
import data from '../Data/DataPrivacyPolicy.json'; 

const PrivacyPolicy = ({ language = 'en' }) => { // Correctly destructure language from props with default value
  const [policyData, setPolicyData] = useState({ title: '', content: '' });

  useEffect(() => {
    // Check if the language-specific data exists and set it
    if(data.privacyPolicy && data.privacyPolicy[language]) {
      setPolicyData(data.privacyPolicy[language]);
    } else {
      // Set default or empty values if the specified language data does not exist
      setPolicyData({ title: 'Data not found', content: 'No content available for the selected language.' });
    }
  }, [language]); // Depend on the 'language' prop to re-run when it changes


  return (
    <Card className='card-page1' title={policyData.title || ''} style={{ width: '90%', margin: '2em auto' }}>
      <p style={{ whiteSpace: "pre-wrap" }}>{policyData.content || 'No content available'}</p>
    </Card>
  );
}

export default PrivacyPolicy;
