//var/www/website2023/afraapache.com/react-app/src/pages/AAContentDetails.js

import React from 'react';
import contentData from '../Data/AAContentDetails.json';

const AAContentDetails = ({ language = 'en' }) => {
  const content = contentData[language];

  // Function to replace newlines with HTML <br> tags
  const formatDescription = (text) => {
    return {__html: text.replace(/\n/g, '<br><br>')};
  };

  return (
    <div className="content-details">
      <h1 className='color-index'>{content.title}</h1>
      <h2 className='color-index'>{content.subtitle}</h2>
      <p className='color-index' dangerouslySetInnerHTML={formatDescription(content.description)}></p>
    </div>
  );
};

export default AAContentDetails;
