// /var/www/website2023/afraapache.com/react-app/src/pages/Contact.js

import React from 'react';
import { Card } from 'primereact/card';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';

// Correctly import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import '../style/Contact.scss';

const Contact = () => {
  const teamData = [
    {
      title: "Team Supported: Bangkok, Thailand",
      address: "Krung Thep Maha Nakhon (Bangkok Thailand): CHALOEM PHRAKIAT RAMA 9 SOI SOI 61 DOK MAI SUBDISTRICT, Prawet District, Bangkok 10250",
      img: "https://example.com/images/bangkok.jpg"
    },
    {
      title: "Main Office: Khon Kaen, Thailand",
      address: "3/643 , Alley18, Mueang KhonKaen, Muang Gao Sub-district, Glaang Meuang Road, KhonKaen Province, zip code 40000",
      img: "https://example.com/images/khonkaen.jpg"
    },
    {
      title: "Team Supported: Khao Yai - Nakhon Ratchasima Province",
      address: "Our team in Nakhon Ratchasima Province supports regional development and services.",
      img: "https://example.com/images/nakhonratchasima.jpg"
    }
  ];

  return (
    <>
   
   <div class="dark-bg-2">
    <div class="image-layer image-first"></div>
    <div class="image-layer image-second"></div>
    <div class="image-layer image-third"></div>
    <div class="image-layer image-fourth"></div>
</div>


    <Swiper
      modules={[Pagination, Autoplay]}
      slidesPerView={2}
      spaceBetween={30}
      loop={true}
      autoplay={{ delay: 8000, disableOnInteraction: false }}
      className="mySwiper-Contact mt-5"
    >
      {teamData.map((team, index) => (
        <SwiperSlide key={index} style={{ width: "300px" }}>


          <Card className='dark-card-Contact' title={team.title} >
            <p>{team.address}</p>
          </Card>
        </SwiperSlide>
      ))}
    </Swiper>
    </>
  );
}

export default Contact;
