///var/www/website2023/afraapache.com/react-app/src/pages/TermsAndConditions.js

import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';

// Sample JSON Data Path (Ensure to create and import actual data)
import termsData from '../Data/DataTermsAndConditions.json';

const TermsAndConditions = ({ language = 'en' }) => {
  const [terms, setTerms] = useState({ title: '', content: '' });

  useEffect(() => {
    // Check if the language-specific terms exist and set them
    if (termsData.termsAndConditions && termsData.termsAndConditions[language]) {
      setTerms(termsData.termsAndConditions[language]);
    } else {
      // Set default or empty values if the specified language data does not exist
      setTerms({ title: 'Data not found', content: 'No terms available for the selected language.' });
    }
  }, [language]); // Depend on the 'language' prop to re-run when it changes

  // Function to replace newlines with HTML <br> tags for better formatting
  const formatDescription = (text) => {
    return { __html: text.replace(/\n/g, '<br>') };
  };

  return (
    <Card title={terms.title || 'Terms and Conditions'} style={{ width: '90%', margin: '2em auto' }}>
      <div dangerouslySetInnerHTML={formatDescription(terms.content || 'No terms available')} />
    </Card>
  );
}

export default TermsAndConditions;
