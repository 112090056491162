///var/www/website2023/afraapache.com/react-app/src/Component/MenuComponent.js

import React from 'react';
import { Menu } from 'primereact/menu';
import '../style/MenuSidebar.scss';



export default function MenuComponent() {
    const items = [
        { label: 'AA', icon: 'pi pi-chevron-right',url: '/' },
        { label: 'Privacy Policy ', icon: 'pi pi-chevron-right',url: '/privacypolicy'  },
        { label: 'Terms And Conditions', icon: 'pi pi-chevron-right',url: '/termsandconditions'  },
        { label: 'Vision', icon: 'pi pi-chevron-right',url: '/vision' },
        { label: 'Timeline', icon: 'pi pi-chevron-right',url: '/timeline'  },
        { label: 'Contact', icon: 'pi pi-chevron-right',url: '/contact'  },
        { label: 'BLOG', icon: 'pi pi-external-link',url: 'https://blog.ttt-website.com/'  },

        {
            label: 'WebSite Solutions',
            icon: 'pi pi-search',
            items: [
                { label: 'รับทำเว็บไซต์', icon: 'pi pi-external-link',url: 'https://www.ttt-website.com/' },
                { label: 'Web Design ', icon: 'pi pi-external-link',url: 'https://www.rampagesoft.com/webdesign/' },
                { label: 'Development', icon: 'pi pi-external-link',url: 'https://dev.rampagesoft.com/' },
                { label: 'SEO Services', icon: 'pi pi-external-link',url: 'https://seo.rampagesoft.com/' },
                { label: 'Content Creation', icon: 'pi pi-external-link',url: 'https://seo.rampagesoft.com/' },
                { label: 'Backlink Building', icon: 'pi pi-external-link',url: 'https://seo.rampagesoft.com/'},     
                { label: 'Website Maintenance', icon: 'pi pi-external-link',url: 'https://www.rampagesoft.com/webdesign/' },     
            ]
        },


        {
            label: 'Development',
            icon: 'pi pi-search',
            items: [
                { label: 'Developer', icon: 'pi pi-external-link',url: 'https://dev.rampagesoft.com/'},
                { label: 'Business Support', icon: 'pi pi-external-link',url: 'https://dev.rampagesoft.com/' },
                { label: 'Rss & Bot Data Extraction', icon: 'pi pi-external-link',url: 'https://dev.rampagesoft.com/' },
                { label: 'API Payment Gateway', icon: 'pi pi-external-link',url: 'https://dev.rampagesoft.com/' },
                { label: 'SMS Gateway', icon: 'pi pi-external-link',url: 'https://dev.rampagesoft.com/' }, 
            ]
        },


        {
            label: 'Cloud Solutions',
            icon: 'pi pi-search',
            items: [
                { label: 'Domain & DNS', icon: 'pi pi-external-link',url: 'https://www.siamwebsite.me/' },
                { label: 'Cloud Server Linux Solutions', icon: 'pi pi-external-link',url: 'https://www.siamwebsite.me/' },
                { label: 'Security And Protections', icon: 'pi pi-external-link',url: 'https://www.siamwebsite.me/' },
                { label: 'Cloudflare Installation', icon: 'pi pi-external-link',url: 'https://www.siamwebsite.me/' }
            ]
        },
      
    ];

    return (
        <div className="card card-MenuComponent">
            <Menu model={items} className="custom-menu-MenuComponent"/>
        </div>
    );
}
