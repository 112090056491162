///var/www/website2023/afraapache.com/react-app/src/App.js

import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

import MenuComponent from './Component/MenuComponent';
import AAContentDetails from './pages/AAContentDetails';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import Vision from './pages/Vision';
import Contact from './pages/Contact';
import Timeline from './pages/Timeline';

import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';  // Import Button if needed for toggling
import { Card } from 'primereact/card';


import { SpeedDial } from 'primereact/speeddial';
import './style/App.scss';
import './style/SpeedDialStyles.scss';
import './style/Sidebar.scss';

function App() {
  const [language, setLanguage] = useState('en');
  const [visibleRight, setVisibleRight] = useState(false); // State for controlling the visibility of the right sidebar
  const [visibleSidebar, setVisibleSidebar] = useState(window.innerWidth > 768); // Sidebar visible by default on desktop


  // Declare dataLayer variable
  let dataLayer = [];
  useEffect(() => {
    // Load Google Analytics Script dynamically
    const script = document.createElement('script');
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-GRKNQGNJ2S";
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || dataLayer; // Use declared dataLayer variable
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', 'G-GRKNQGNJ2S');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Adjust the visibility based on window resize
    const handleResize = () => {
      setVisibleSidebar(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = () => {
    setVisibleSidebar(!visibleSidebar);
  };




  const changeLanguage = (lang) => {
    console.log('Changing language to:', lang); // Debugging output
    setLanguage(lang);
  };

  const speedDialItems = [
    {
      label: 'English',
      template: (item) => <img src="https://afaa.website/s/ddbd46.png" alt="English" onClick={() => changeLanguage('en')} style={{ width: 30 }} />,
    },
    {
      label: 'Thai',
      template: (item) => <img src="https://afaa.website/s/179124.webp" alt="Thai" onClick={() => changeLanguage('th')} style={{ width: 30 }} />,
    },

    {
      label: 'French',
      template: (item) => <img src="https://afaa.website/s/54f790.png" alt="French" onClick={() => changeLanguage('fr')} style={{ width: 30 }} />,
    },


    {
      label: 'Japanese',
      template: (item) => <img src="https://afaa.website/s/27ae55.webp" alt="Japanese" onClick={() => changeLanguage('jp')} style={{ width: 30 }} />,
    },



    {
      label: 'Chinese',
      template: (item) => <img src="https://afaa.website/s/632764.webp" alt="Chinese" onClick={() => changeLanguage('zh')} style={{ width: 30 }} />,
    },

  ];

  const socialMediaItems = [
    { label: '', icon: 'pi pi-whatsapp', url: 'https://wa.me/message/WFPUI4FAAKGCK1' },
    { label: '', image: 'https://afaa.website/s/f13d9d.svg', url: 'https://line.me/ti/p/@tttwebsite' },
    { label: '', icon: 'pi pi-twitter', url: 'https://twitter.com/AFRAAPACHE' },
    { label: '', icon: 'pi pi-youtube', url: 'https://www.youtube.com/@rampagesoft' },
    { label: '', icon: 'pi pi-github', url: 'https://github.com/siamwebsite' },
    { label: '', icon: 'pi pi-reddit', url: 'https://www.reddit.com/user/seo_siamwebsite/' }
  ];

  const handleSocialMediaNavigate = (url) => {
    window.open(url, '_blank');
  };


  return (
    <div className="App">

{!visibleSidebar && (
        <Button icon="pi pi-arrow-right" label='MENU' onClick={toggleSidebar} className="mobile-sidebar-toggle" />
      )}

      <Sidebar className='p-sidebar-index1 sidebar-custom-index1' visible={visibleSidebar} onHide={() => setVisibleSidebar(false)} position="left" modal>
        <MenuComponent />
      </Sidebar>



      <div className="content">
        <Routes>
          <Route path="/" element={<AAContentDetails language={language} />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy language={language} />} />
          <Route path="/termsandconditions" element={<TermsAndConditions language={language} />} />
          <Route path="/vision" element={<Vision language={language} />} />

          <Route path="/timeline" element={<Timeline />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>


      {/* Right Sidebar */}
      <Sidebar visible={visibleRight} position="right" onHide={() => setVisibleRight(false)} modal={true} className="custom-sidebar p-sidebar-index1">
        <h6> Thank You For Reaching Out</h6>
        {/* Display social media items as cards */}
        <div className="social-media-container">
          {socialMediaItems.map((item, index) => (
            <Card cey={index} className="social-media-card mt-3" onClick={() => handleSocialMediaNavigate(item.url)}>
           
           {item.image ? <img src={item.image} alt="Icon" style={{ width: '30px' }} /> : <i className={item.icon} style={{ fontSize: 'xx-large' }} />}

              <span>{item.label}</span>
            </Card>
          ))}
        </div>

      </Sidebar>

      {/* Toggle Button for Right Sidebar */}
      <Button icon="pi pi-spin pi-send"
        label="Contact AFRA APACHE"
        className="p-button-rounded speed-index"
        onClick={() => setVisibleRight(true)} style={{ position: 'fixed', right: '10px', bottom: '50px' }} />

      <SpeedDial
        model={speedDialItems}
        direction="left"
        hideOnClick={false}
        style={{ position: 'absolute', top: '20px', right: '20px' }}
        radius={80}
        className="speed-dial-custom"
      >
      </SpeedDial>

    </div>
  );
}

export default App;
