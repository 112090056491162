import React from 'react';
import { Card } from 'primereact/card';
import { Timeline } from 'primereact/timeline';
import '../style/Timeline.scss'; // Import the SCSS file for styling

const TimelinePage = () => {


  const formatDescription = (text) => {
    return { __html: text.replace(/\n/g, '<br>') };
  };

  const events = [
    { status: '2016 - SIAMWEBSITE', date: '2016', 
    description: 'SIAMWEBSITE EXCELS IN WEB DEVELOPMENT OFFERING EXPERTISE IN PHP, HTML, CSS, AND JAVASCRIPT. WE RE DEDICATED TO CLIENT SATISFACTION AND CONTINUOUS INNOVATION, ENSURING CUSTOMIZED SOLUTIONS THAT CATER TO YOUR SPECIFIC NEEDS. TRUST SIAMWEBSITE TO ENHANCE YOUR DIGITAL PRESENCE WITH EXCEPTIONAL WEB EXPERIENCES.', image: 'https://afaa.website/s/bebaea.png' },
    
    { status: '2017 - RAMPAGESOFT', date: '2017', 
    description: 'RAMPAGESOFT IS YOUR TRUSTED PARTNER FOR CRAFTING EXCEPTIONAL DIGITAL EXPERIENCES THAT EMPOWER YOUR BUSINESS. OUR COMPREHENSIVE SUITE OF SERVICES INCLUDES WEBSITE DESIGN, MOBILE APP DEVELOPMENT, BUSINESS TECHNOLOGY SOLUTIONS, AND MULTI-LEVEL MARKETING (MLM) SYSTEMS. WE ARE COMMITTED TO DELIVERING INNOVATIVE AND HIGH-QUALITY SOLUTIONS THAT MEET YOUR UNIQUE NEEDS AND DRIVE BUSINESS GROWTH.', image: 'https://afaa.website/s/ff6970.png' },
    
    { status: '2018 - TTT-WEBSITE', date: '2018', 
    description: 'TTT-WEBSITE OFFERS PROFESSIONAL, AFFORDABLE WEB DESIGN SOLUTIONS, STARTING WITH COMPETITIVE PRICING THAT ACCOMMODATES EVERY BUDGET. SPECIALIZING IN VARIOUS SECTORS SUCH AS CAR RENTALS, SPAS, MANUFACTURING, SERVICES, REAL ESTATE, AND MORE, WE ENSURE YOUR BUSINESS IS WELL-REPRESENTED ONLINE. OUR COMMITMENT EXTENDS BEYOND DESIGN TO INCLUDE COMPREHENSIVE WEB MAINTENANCE AND E-COMMERCE SOLUTIONS, HELPING YOUR BUSINESS SUCCEED IN THE DIGITAL WORLD.', 
    image: 'https://afaa.website/s/7414b1.webp' },

    { status: '2019 - AFRA APACHE', date: '2019', 
    description: 'AFRA APACHE IS A LEADING PROVIDER OF WEB DEVELOPMENT, SEO, AND CLOUD SERVER SOLUTIONS, EMPOWERING BUSINESSES TO THRIVE IN THE DIGITAL LANDSCAPE. OUR TEAM OF EXPERIENCED DEVELOPERS AND DIGITAL MARKETING EXPERTS IS DEDICATED TO CRAFTING TAILORED SOLUTIONS THAT MEET YOUR UNIQUE NEEDS AND DRIVE BUSINESS GROWTH.', 
    image: 'https://afaa.website/s/563dfa.svg' },

    { status: '2020 - DEVELOPMENT, SEO MARKETING AND CONTENT MANAGEMENT SYSTEMS (CMS)', date: '2020', 
    description: 'AFRA APACHE IS DEDICATED TO EXPANDING AND ENHANCING TECHNOLOGICAL CAPABILITIES, FOCUSING ON THE DEVELOPMENT OF CUTTING-EDGE ONLINE STORES BOOKING SYSTEMS, AND CONTENT MANAGEMENT SYSTEMS (CMS)\n\nCONSTANTLY EXPANDING OUR CAPABILITIES TO MEET THE EVER-CHANGING NEEDS OF BUSINESSES WORLDWIDE.\n\nOUR APPROACH GOES BEYOND MERE PROMOTION AIMING TO TRULY ELEVATE YOUR BUSINESS ON A GLOBAL SCALE THROUGH STRATEGIC WEB DEVELOPMENT, SEO, AND INNOVATIVE MARKETING PRACTICES.', 
    image: 'https://afaa.website/s/563dfa.svg' },

    { status: '2021 - CMS PLUGIN WORDPRESS, CONTENT, GAMING COMMUNITY', date: '2021', 
    description: 'IN 2021, AFRA APACHE LEVERAGED ITS EXPERTISE IN WORDPRESS TO OFFER ADVANCED CMS SOLUTIONS, SIGNIFICANTLY ENHANCING DIGITAL EXPERIENCES ACROSS THE WEB. THE COMPANY ALSO DOVE INTO CONTENT CREATION AND ACTIVELY SUPPORTED THE GAMING COMMUNITY, THEREBY CEMENTING ITS ROLE IN FOSTERING DIVERSE ONLINE COMMUNITIES. THIS STRATEGIC EXPANSION NOT ONLY BOOSTED ITS SERVICE OFFERINGS BUT ALSO SPURRED OVERALL BUSINESS GROWTH.', 
    image: 'https://afaa.website/s/563dfa.svg' },

    { status: '2022 - GOVERNMENT PROJECTS, API, SMS SERVICES', date: '2022', 
    description: 'IN 2022, AFRA APACHE ENGAGED IN STRATEGIC GOVERNMENT PROJECTS, ENHANCING PUBLIC SERVICES THROUGH INNOVATIVE API AND SMS SOLUTIONS. THIS INVOLVEMENT NOT ONLY IMPROVED COMMUNICATION EFFICIENCIES BUT ALSO EXPANDED OUR TECHNOLOGICAL FOOTPRINT IN GOVERNMENT SECTORS.',
     image: 'https://afaa.website/s/563dfa.svg' },

    { status: '2023 - ADVANCING TECHNOLOGY : BLOGGING, RSS, BOTS, CLOUD, REACT, PYTHON, GO YOLOV8', date: '2023', 
    description: 'IN 2023, AFRA APACHE ADVANCED ITS TECHNOLOGICAL CAPABILITIES BY INTEGRATING COMPREHENSIVE CLOUD SOLUTIONS, ENHANCED BLOGGING AND RSS TOOLS, AUTOMATED BOT SERVICES, AND ROBUST PROGRAMMING WITH PYTHON, REACT, AND THE INNOVATIVE GO YOLOV8 VISION AI. THESE DEVELOPMENTS DEMONSTRATE OUR COMMITMENT TO LEVERAGING CUTTING-EDGE TECHNOLOGIES TO DELIVER SOPHISTICATED DIGITAL SOLUTIONS.', image: 'https://afaa.website/s/563dfa.svg' },

    { status: '2024 - ADVANCED SPA MASSAGE BOOKING, DOMAIN MANAGEMENT, AND AI-DRIVEN CMS', date: '2024', 
    description: 'IN 2024, WE ADVANCED OUR DEVELOPMENT IN SPA AND MASSAGE BOOKING SYSTEMS USING ANGULAR, REFINED CMS, AND EFFECTIVE DOMAIN MANAGEMENT. ADDITIONALLY, OUR AI TECHNOLOGY WAS ENHANCED TO AUTONOMOUSLY SUMMARIZE ARTICLES FOR AA-CHAT, SIGNIFICANTLY IMPROVING USER ENGAGEMENT AND SYSTEM EFFICIENCY.', image: 'https://afaa.website/s/563dfa.svg' },

    { status: '2025-2026 - NEW AI AND AUTOMATION SOLUTIONS FOR ENHANCED BUSINESS EFFICIENCY', date: '2025', 
    description: 'IN 2025-2026, WE INTRODUCED CUTTING-EDGE AI AND AUTOMATION TECHNOLOGIES AIMED AT STREAMLINING BUSINESS PROCESSES. OUR NEW AI SOLUTIONS AND POS SERVICES ARE DESIGNED TO OPTIMIZE OPERATIONS, ENSURING ENHANCED EFFICIENCY AND EFFECTIVENESS IN VARIOUS INDUSTRIES',
     image: 'https://afaa.website/s/563dfa.svg' },

    { status: '2027-2028 - SOFTWARE OPEN SOURCE APPLICATIONS AND SECURITY SOLUTIONS', date: '2027', 
    description: 'IN 2027-2028, WE FOCUSED ON LAUNCHING OPEN SOURCE SOFTWARE APPLICATIONS INTEGRATED WITH COMPREHENSIVE SECURITY SOLUTIONS. THESE INNOVATIONS ARE SPECIFICALLY DESIGNED TO EMPOWER BUSINESSES BY PROVIDING THE FLEXIBILITY OF OPEN SOURCE PLATFORMS WHILE MAINTAINING HIGH SECURITY STANDARDS.\n\nTHIS APPROACH NOT ONLY ENHANCES OPERATIONAL EFFICIENCY BUT ALSO ENSURES THE PROTECTION OF CRITICAL DATA, MAKING IT A ROBUST SOLUTION FOR MODERN BUSINESS NEEDS.', image: 'https://afaa.website/s/563dfa.svg' },

    { status: '2029-2030 - EXPANDING HARDWARE I/O AND SOFTWARE INTEGRATIONS', date: '2030', 
    description: 'IN 2029-2030, WE EXPANDED OUR OFFERINGS TO INCLUDE ADVANCED HARDWARE I/O SOLUTIONS, INTEGRATING STATE-OF-THE-ART SOFTWARE AND THIRD-PARTY TECHNOLOGIES. THIS STRATEGIC ENHANCEMENT ALLOWED US TO OFFER COMPREHENSIVE SYSTEMS THAT OPTIMIZE HARDWARE PERFORMANCE AND INTEROPERABILITY, ENSURING SEAMLESS INTEGRATION AND INCREASED EFFICIENCY FOR BUSINESSES ACROSS VARIOUS INDUSTRIES.', 
    image: 'https://afaa.website/s/563dfa.svg' }

  ];

  const customizedMarker = (item) => {
    return (
      <span className="custom-marker" style={{ backgroundColor: '#9C27B0' }}>
        <i className="pi pi-calendar" style={{ color: 'white' }}></i>
      </span>
    );
  };

  const customizedContent = (item) => {
    return (
      <Card title={item.status} subTitle={item.date} className="dark-card">
        {item.image && <img src={item.image} alt={item.status} style={{ width: '200px', }} />}
        <p className='mt-2' dangerouslySetInnerHTML={formatDescription(item.description)} />
      </Card>
    );
  };

  return (
    <div className="timeline-container">
      <Timeline value={events} align="alternate" marker={customizedMarker} content={customizedContent} />
    </div>
  );
}

export default TimelinePage;
