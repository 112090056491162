// /var/www/website2023/afraapache.com/react-app/src/pages/Vision.js

import React from 'react';
import { Card } from 'primereact/card';
import '../style/Vision.scss';

const Vision = () => {
  return (
    <>
      <Card className="vision-card" title="Mission & Vision">
        <p>
          AFRA APACHE มุ่งมั่นที่จะเป็นผู้นำทางด้านเทคโนโลยีในไทย  ขับเคลื่อนการพัฒนาปัญญาประดิษฐ์ไทย และส่งเสริมศักยภาพนักพัฒนาซอฟต์แวร์ไทย เพื่อสร้างสรรค์นวัตกรรม ยกระดับอุตสาหกรรมซอฟต์แวร์ และนำพาประเทศไทยสู่ยุคดิจิทัลอย่างยั่งยืน
        </p>

        <br /><br />
        <h5> เป้าหมาย AFRA APACHE THAILAND</h5>

        <p>
          • ผลักดันเทคโนโลยีปัญญาประดิษฐ์สู่เวทีโลก : มุ่งมั่นสร้างสรรค์ผลงาน สนับสนุนการพัฒนาเทคโนโลยีปัญญาประดิษฐ์ (AI) นำเสนอผลงานระดับสากล ยกระดับชื่อเสียง และความสามารถของ AFRA APACHE THAILAND <br /><br />

          • พัฒนาศักยภาพนักพัฒนาโปรแกรมไทย : ส่งเสริม สนับสนุนนักพัฒนาโปรแกรม หล่อเลี้ยงให้เติบโตเป็นนักพัฒนาชั้นแนวหน้า พร้อมก้าวสู่เวทีระดับโลก<br /><br />

          • ขับเคลื่อนการพัฒนาซอฟต์แวร์ : สนับสนุนการพัฒนาซอฟต์แวร์ ส่งเสริมการใช้ซอฟต์แวร์โอเพนซอร์ส (Open Source) และฟรีแวร์ (Freeware) กระตุ้นให้เกิดการใช้งานซอฟต์แวร์อย่างแพร่หลาย<br /><br />

          • ก้าวเป็นผู้นำซอฟต์แวร์โอเพนซอร์สไทย : พัฒนา ผลิตซอฟต์แวร์โอเพนซอร์ส (Open Source) ที่มีคุณภาพสูง ยกระดับไทยเป็นศูนย์กลางการพัฒนาซอฟต์แวร์โอเพนซอร์สชั้นนำในไทย

        </p>

        <br /><br />

        <h5> วิสัยทัศน์ AFRA APACHE THAILAND </h5>

        <p>
          • มุ่งมั่นพัฒนาอย่างต่อเนื่อง : วางแผน ดำเนินการอย่างจริงจัง พัฒนาองค์กร เทคโนโลยี ทรัพยากรมนุษย์ เพื่อบรรลุเป้าหมาย <br /><br />
          • คิดค้นสร้างสรรค์นวัตกรรม : ส่งเสริมวัฒนธรรมการคิดค้น ริเริ่มสร้างสรรค์นวัตกรรม พัฒนาเทคโนโลยีใหม่ ตอบโจทย์ความต้องการ สร้างความแตกต่าง <br /><br />
          • มุ่งพัฒนาคุณภาพอย่างต่อเนื่อง : พัฒนาคุณภาพสินค้า บริการ กระบวนการทำงาน ประสิทธิภาพองค์กร สร้างความพึงพอใจสูงสุด <br /><br />
          • ยึดมั่นในจริยธรรม : ดำเนินธุรกิจด้วยความซื่อสัตย์ โปร่งใส เป็นธรรม คำนึงถึงผลประโยชน์สูงสุดของสังคม
        </p>

      </Card>


      <Card title="Open Source & AI" className="vision-card">

        <h5> การพัฒนาเกี่ยวกับ Open Source & AI </h5>

        <p>
          • เป้าหมายการเป็นศูนย์กลาง Open Source แห่งชาติ : พัฒนาแพลตฟอร์มสำหรับการแบ่งปัน และการเข้าถึงซอฟต์แวร์ Open Source ที่หลากหลาย เพื่อส่งเสริมให้นักพัฒนาไทยสามารถเข้าถึง และสร้างสรรค์ซอฟต์แวร์ด้วยตนเองได้ง่ายขึ้น <br /><br />

          • การพัฒนาชุมชนนักพัฒนา : สร้างชุมชนนักพัฒนาที่เข้มแข็ง เพื่อสนับสนุนการแลกเปลี่ยนความรู้ การทำงานร่วมกัน และการสร้างนวัตกรรมใหม่ๆ ในสภาพแวดล้อม Open Source <br /><br />

          • เป้าหมายการพัฒนา AI เพื่อสังคม : นำเสนอโซลูชัน AI ที่ทันสมัยเพื่อแก้ไขปัญหาสำคัญๆ รวมถึงการประยุกต์ใช้ AI ในภาคการศึกษา สุขภาพ และการเกษตร  <br /><br />

          • การฝึกอบรม และพัฒนาทักษะ AI ให้กับนักพัฒนา : จัดตั้งโครงการฝึกอบรม เพื่อสร้าง และพัฒนาทักษะในด้าน AI และการเรียนรู้ของเครื่อง ส่งเสริมให้นักพัฒนาไทยมีความสามารถในการสร้าง และใช้เทคโนโลยี AI อย่างมีประสิทธิภาพ
        </p>

        <br /><br />

        <h5> วิสัยทัศน์ Open Source และ AI </h5>

        <p>
        • นวัตกรรมผ่าน Open Source และ AI : มุ่งมั่นในการสร้างนวัตกรรมใหม่ๆ ที่ได้มาจากการใช้แพลตฟอร์ม Open Source และเทคโนโลยี AI เพื่อนำไปสู่การพัฒนาที่ยั่งยืน และการแก้ปัญหาที่เป็นรูปธรรม<br /><br />

        • การสร้างเครือข่ายการเรียนรู้ และนวัตกรรม : เชื่อมโยงกับเครือข่ายนักพัฒนา และนักวิทยาศาสตร์ เพื่อแลกเปลี่ยนความรู้ นวัตกรรม และผลักดันการพัฒนาเทคโนโลยีให้ก้าวหน้าอย่างต่อเนื่อง
        </p>

      </Card>


      <Card title="AFRA APACHE" className="vision-card-2">
        <p>
          ด้วยเป้าหมาย และวิสัยทัศน์ที่ชัดเจน AFRA APACHE มุ่งมั่น ทุ่มเทที่จะบรรลุเป้าหมาย ก้าวสู่ความสำเร็จ สร้างชื่อเสียง นำพา AFRA APACHE THAILAND สู่ยุคดิจิทัลอย่างยั่งยืน
        </p>
      </Card>
    </>
  );
}

export default Vision;